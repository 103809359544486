.name-title-personal-stmt {
  p {
    margin: 1rem 0rem 1rem 0rem;
    text-align: left;
  }

  .personal-stmt {
    min-width: 22rem;
    max-width: 22rem;
  }
}
