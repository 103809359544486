@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Open+Sans:wght@400;600&display=swap");

html {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  display: block;
  --primary-dark: #2c2c3e;
  --secondary-dark: #af7269;
  --secondary-darker: #612f38;
  --accent-dark: #629ae7;
  --accent-darker: #aae0ff;
  --text-dark: #f5eed8;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  border: none;
}
body {
  background-color: var(--primary-dark);
  color: var(--text-dark);
}
a {
  text-decoration: none;
  color: inherit;
}
p {
  font-size: 1rem;
  line-height: 2rem;
}

p::selection {
  background: var(--accent-darker);
  color: var(--text-dark);
  text-shadow: 1px 1px 2px #000000aa;
}

.h1,
.h2,
.h3,
.h4 {
  font-family: "Bebas Neue", sans-serif;
}

.h1 {
  font-size: 4.5rem;
  line-height: 1;
  text-shadow: 1px 1px 2px black;
}
.h2 {
  font-size: 3.5rem;
  line-height: 1;
  text-shadow: 1px 1px 2px black;
}
.h3 {
  font-size: 2.5rem;
  letter-spacing: 0rem;
  line-height: 3rem;
  font-weight: normal;
  text-shadow: 1px 1px 2px black;
}
.h4 {
  font-size: 2rem;
}

.h5 {
  font-size: 1rem;
}

.label {
  font-size: 0.75rem;
}

.text-big {
  font-size: 1.5rem;
  color: var(--secondary-dark);
  font-weight: 500;
}

.text-small {
  font-size: 0.88rem;
  color: var(--text-dark);
  opacity: 0.65;
}

@media (max-width: 494px) and (min-width: 360px) {
  p {
    font-size: 1rem;
    line-height: 1.3rem;
  }
  .h1 {
    font-size: 3.5rem;
  }
}
