.project-poster-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 39rem;
  min-width: 22rem;
  background: linear-gradient(
    65deg,
    rgba(44, 44, 62, 1) 64%,
    rgba(98, 154, 231, 1) 190%
  );
  position: relative;
  border-radius: 0.8rem;
  transform: rotate(-1deg);
  box-shadow: 0 3px 9px 1px var(--text-dark);

  .bottom-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.project-image {
  position: relative;
  width: 95%;
  height: auto;
  margin: 1rem 0rem 0rem;

  img {
    width: 100%;
    height: auto;
    border-radius: 0.8rem;
    border-width: 0.13rem;
    border-style: solid;
    border-color: var(--text-dark);
  }
}

.project-description-title {
  width: 80%;
  margin: 1rem;
}

.project-techs {
  margin: 1rem 0 0 0;
  padding: 0;
  gap: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  flex-wrap: wrap;
}

.tech {
  color: #ffffff;
  text-align: center;
  margin: 0;
  padding: 0.5rem;
  border: 1px solid #ffffff;
  border-style: groove;
  border-radius: 1.5rem;
}
.project-poster-card:hover {
  transform: rotate(0deg);
  transition: transform 0.2s;
}

.tape-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  margin: 1.5rem 0.5rem 0.5rem 0.5rem;
}

@media (max-width: 596px) and (min-width: 360px) {
  .tape-links {
    display: none;
    margin: 0;
  }
}
