.scotch-tape-link {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;

  a {
    min-width: 10rem;
    min-height: 3.5rem;
    text-align: center;
    padding: 0.9rem;
    transform: rotate(-10deg);
    background: rgba(255, 255, 235, 0.6);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  }
}
