.navcolumn__link--span {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin: 0.5rem;
  user-select: none;
  cursor: pointer;
  max-width: 12.5rem;

  .icon {
    padding-bottom: 0.2rem;
  }
}

.navcolumn__link--span:hover {
  filter: brightness(200%);
  text-decoration: underline;
  text-decoration-color: var(--accent-dark);
  .icon {
    color: var(--accent-dark);
  }

  .icon-wiggle {
    animation: wiggle 0.5s ease-in-out;
  }

  .icon-beat {
    animation: beat 2s linear;
  }

  .icon-fade {
    animation: fadeOutToCorner 1s forwards;
  }
}

@keyframes wiggle {
  0%,
  100% {
    transform: rotate(-15deg);
  }
  50% {
    transform: rotate(15deg);
  }
}

@keyframes beat {
  0%,
  50%,
  100% {
    transform: scale(1, 1);
  }
  30%,
  80% {
    transform: scale(0.85, 0.85);
  }
}

@keyframes fadeOutToCorner {
  from {
    opacity: 1;
    transform: translate(0, 0);
  }
  to {
    opacity: 0;
    transform: translate(50px, -50px);
  }
}

@media (max-width: 494px) and (min-width: 360px) {
  .NavColumnLinks {
    display: none;
  }
}
