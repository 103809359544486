.socialmedia--span {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin: 1.5rem 0.5rem 0.5rem 0.5rem;

  .icon:hover {
    filter: brightness(200%);
  }
}


