.layout-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 3rem;
  min-height: 100vh;
  max-width: 1512px;
  min-width: 360px;
}

.body-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  padding: 0 6rem 0 6rem;
}

.left-section {
  top: 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  position: sticky;
  padding: 13rem 0 10rem 0;
  min-width: 50%;
  max-height: 100svh;
}

.right-section {
  display: block;
  padding: 7rem 0 10rem 0;
}

.content-container {
  scroll-margin-top: 6rem;
  margin-bottom: 3rem;
  padding: 3rem 0 3rem 0;
}

.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 834px) and (min-width: 430px) {
  .body-container {
    flex-direction: column;
    padding: 0 3rem;
  }

  .left-section,
  .right-section {
    min-width: 100%;
    padding: 1rem 0;
    position: relative;
  }

}

@media (max-width: 429px) and (min-width: 360px) {
  .body-container {
    flex-direction: column;
    padding: 0 1.5rem;
  }

  .left-section,
  .right-section {
    min-width: 100%;
    padding: 1.5rem 0;
    position: relative;
  }
}
