.project-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4rem;
}

.page-header {
  color: var(--secondary-dark);
  text-shadow: 1px 1px 2px black;
}
